<template>
 <div class="f-box">
     <div class="handle" v-if="tableData.length>0">
        <div class="h-img"><img src="@/assets/img/chou_02.png" alt=""></div>
        <div class="user">
          <span style="margin-right:0;">抽签台编号：</span>
          <span style="margin-right:25px;">{{ taihao }}</span>
          <span style="margin-right:0;">抽签责任人：</span>
          <span>{{username}}</span>
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="showChouList">抽签列表</el-dropdown-item>
                  <el-dropdown-item @click.native="back">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <div class="h-btn" @click="showChouList">抽签列表</div> -->
      </div>
      <div class="table-box" v-if="!showQList">
        <div :class="['no-table',iptfs ?'bg-filter':'']" v-if="tableData.length == 0 && !showQList">
          <img src="@/assets/img/hehe_03.png" alt="">
        </div>
        <div :class="['p-btn',iptfs?'center-btn':'']" v-if="tableData.length == 0">
            <el-input v-model="input" @focus="fcs" @blur="blu" placeholder="请输入抽签顺序号" style="width:350px" @keyup.enter.native="getChou">
                <el-button slot="append" icon="el-icon" @click="getChou" style="font-size: 20px;">搜索</el-button>
                <template slot="prepend"><span style="font-size: 20px;line-height: 20px;">VIP</span></template>
            </el-input>
        </div>
        <div class="s-btn" v-else>
            <el-input v-model="input" @focus="fcs" @blur="blu" placeholder="请输入抽签顺序号" style="width:350px" @keyup.enter.native="getChou">
                <el-button slot="append" icon="el-icon" @click="getChou" style="font-size: 20px;">搜索</el-button>
                <template slot="prepend"><span style="font-size: 20px;line-height: 20px;">VIP</span></template>
            </el-input>
        </div>
        <div class="flex-box"  v-if="tableData.length>0">
          <div style="margin-bottom:20px;margin-top:-20px;text-align: center;font-weight:bold;font-size:18px;" v-if="arr.length>0">
            <span style="color:#b7001d;">权利人：</span>
            <el-radio-group v-model="radios" @change="choose">
              <el-radio v-for="(item,key,index) in Data" :label="key" :key="index">{{key}}</el-radio>
            </el-radio-group>
          </div>
          <el-table
            :data="tableData1"
            border
            style="width: 100%" class="table1">
              <el-table-column prop="sort" label="抽签顺序号" align="center">
              </el-table-column>
              <el-table-column prop="contract" label="合同编号" align="center">
              </el-table-column>
              <el-table-column label="权利人名称"  align="center">
                {{radios}}
              </el-table-column>
                <el-table-column prop="draw_time" label="抽签时点" align="center">
                  <template>
                    {{time}}
                  </template>
                </el-table-column>
                <el-table-column label="总计应抽签间数" align="center">
                    <template>
                      {{tao}}
                    </template>
                </el-table-column>
            </el-table>
          <el-table
                :data="tableData"
                border
                show-summary
                style="width: 100%">
                <!-- <el-table-column prop="house_type" label="抽签标段" align="center">
                </el-table-column> -->
                <el-table-column prop="area" label="户型面积（㎡）" align="center">
                </el-table-column>
                <el-table-column prop="has_num" sum-text label="应抽签间数" align="center">
                </el-table-column>
                <el-table-column prop="draw_num" sum-text label="已抽签选定间数" align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.draw_num" placeholder="请输入" size="small" style="width:120px;"></el-input>
                    </template>
                </el-table-column>
            </el-table>
          <div class="page-btns">
            <div :class="['sure-btn',isUp?'upbtn':'']" style="margin-right: 35px;" @click="upTable">确认提交</div>
            <div class="sure-btn" @click="clearData">重置</div>
          </div>
        </div>
    </div>
    <div class="table-box" v-else>
      <div class="flex-box">
              <el-button type="primary" style="margin-bottom: 20px;" @click="reback">返回</el-button>
              <el-button type="primary" style="margin-bottom: 20px" @click="daoChu">导出</el-button>
              <el-table
                  :data="cList"
                  border
                  style="width: 100%" class="table1">
                  <el-table-column label="抽签顺序号" prop="sort"  align="center">
                  </el-table-column>
                  <el-table-column label="权利人姓名" prop="name"  align="center">
                  </el-table-column>
                  <el-table-column label="合同号" prop="contract"  align="center">
                  </el-table-column>
                  <!-- <el-table-column label="抽签标段" prop="house_type"  align="center">
                  </el-table-column> -->
                  <el-table-column label="户型面积（㎡）" prop="area"  align="center">
                  </el-table-column>
                  <el-table-column label="应抽套数" prop="has_num"  align="center">
                  </el-table-column>
                  <el-table-column label="实抽套数" prop="draw_num"  align="center">
                  </el-table-column>
              </el-table>
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-size="10"
                layout="total, prev, pager, next, jumper"
                :total="CData.total">
              </el-pagination>
          </div>
    </div>
 </div>
</template>

<script>
export default {
  data () {
    return {
        currentPage:1,
        showQList:false,
        time:'',
        radios:'',
        input:'',
        tableData1: [],
        tableData: [],
        Data:[],
        arr:[],
        username:'',
        tao:'',
        cList:[],
        CData:[],
        isUp:false,
        taihao:'',
        iptfs:false,
    }
  },
  methods:{
    daoChu(){
      let uin = localStorage.getItem('uin')
      window.location.href = $baseUrl+`export/export_draw?useruid=${uin}`
    },
      fcs(){
          this.iptfs = true
      },
      blu(){
          if(this.input == ''){
            this.iptfs = false
          }
      },
      reback(){
        this.showQList = false;
      },
      showChouList(){
        this.currentPage = 1
        this.getChouList();
        this.showQList = true
      },
      getChouList(){
        this.$http.post('/index/draw_list',{page:this.currentPage}).then(res=>{
          this.CData = res.data.data
          this.cList = res.data.data.list
        })
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getChouList();
      },
    clearData(){
      this.radios = this.input = this.time = this.tao = ''
      this.tableData = this.tableData1 = this.Data = this.arr = [];
      this.iptfs = false
    },
    upTable(){
      let dt = {detail:this.tableData,draw_time:this.time}
      this.$http.post('/index/draw_info_submit',dt).then(res=>{
        if(res.data.code == 0){
            this.Data[this.radios].isUp = this.isUp = true
            $success();
          }
      })
    },
    choose(){
      let i = 0
      this.tableData1 = [];
      this.tableData = this.Data[this.radios]
      this.isUp = this.Data[this.radios].isUp
      this.tableData1.push(this.Data[this.radios][0])
      this.tableData.forEach(item=>{
        i+=parseInt(item.has_num)
      })
      this.tao = i
    },
    // 获取抽签信息
    getChou(){
      // this.tableData = this.tableData1 = this.arr = this.Data = []
      // this.radios = ''
      let dt = {sort:'vip'+this.input}
      this.$http.post('/index/draw_info',dt).then(res=>{
        console.log(res)
        this.time = res.data.draw_time
        this.Data = res.data.data
        let i = 0;
        this.arr = []
        for(let key in res.data.data){
            i+=1;
            res.data.data[key].isUp = false
            this.arr.push(key)
        }
        console.log(res.data.data)
        this.radios = this.arr[0]
        this.choose()
      })
    },
    back(){
      this.$router.push({path:'/'})
      localStorage.clear();
    },
  },
  created(){
    this.username = localStorage.getItem('name')
    this.taihao = localStorage.getItem('taihao')
  },
}

</script>
<style lang="less" scoped>
.f-box{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.table-box{
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}
.handle{
  position: relative;
  background: #b5011d;
  height: 60px;
  line-height: 60px;
  padding:0 30px;
  color: gold;
  font-size: 24px;
  .user{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0,-50%);
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    img{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 10px;
    }
    span{
      margin-right: 15px;
    }
  }
}
.s-btn{
    margin: 30px auto 0;
    text-align: center;
}
.s-msg{
    padding: 30px 30px 0;
    font-size: 16px;
    line-height: 22px;
    .s-title{
        position: relative;
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 10px;
        line-height: 24px;
        margin-bottom: 5px;
        &::after{
            content: '';
            width: 150px;
            background-image: linear-gradient(to right,#000,#fff);
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .t-msg{
        font-size: 14px;
        line-height: 40px;
        .tag{
            &>div{
                display: inline-block;
                margin-right: 68px;
            }
            span:first-child{
                margin-right: 20px;
                display: inline-block;
                color: #999;
            }
        }
    }
}
.s-radio{
    padding: 0 30px;
    margin-top: 10px;
}
.sure-btn{
    width: 120px;
    height: 40px;
    background: #b7001d;
    font-size: 14px;
    color: gold;
    text-align: center;
    border-radius: 6px;
    line-height: 40px;
    cursor: pointer;
}
.flex-box{
    margin: 60px 40px 0;
}
/deep/.el-table thead th.is-leaf{
    color: gold;
    background-color: #b7001d!important;
}
/deep/.el-table thead.is-group th{
    color: gold;
    background-color: #b7001d!important;
}
.page-btns{
  display:flex;
  align-items: center;
  justify-content: center;
  margin:70px 0;
}
/deep/.flex-box .el-input .el-input__inner{
  text-align: center;
}
.el-message__content{
  font-size: 36px!important;
}
.h-btn{
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0,-50%);
  color: #fff;
  font-weight: bold;
  right: 200px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
}
.no-table{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  img{
    width:100%;
    height: 100%;
    object-fit: cover;
  }
}
.upbtn{
  opacity: .6;
}
.bg-filter{
  filter: blur(6px);
}
.p-btn{
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translate(-50%,0);
}
.center-btn{
  bottom: 50%;
  transform: translate(-50%,-50%) scale(2.5);
}
.h-img{
  line-height: 0;
  height: 100%;
  overflow: hidden;
  img{
    height: 100%;
    margin-top: 4px;
  }
}
/deep/.el-pagination{
  margin: 20px 0;
}
::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
}
</style>